import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Grid,
    Box,
    Divider,
    Chip,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    styled,
    TableCell,
    TableRow,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableHead,
    tableCellClasses,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PaymentIcon from '@mui/icons-material/Payment';
import {
    BorderedBox,
    BorderedContainedBox,
} from '../../../components/core/box';
import { useBillingService } from '../../../services/billing.service';
import {
    IActiveSubscriptionResponseData,
    IProratedAmountResponseData,
    ISubscription,
} from '../../../services/interfaces';
import { BootstrapDialogSmall } from '../../../components/core/dialog';
import { UtilsService } from '../../../services/utils.service';
import { useSearchParams } from 'react-router-dom';
import { useQueryparamServices } from '../../../services/query-param.service';
import { useSnackbar } from 'notistack';
import { AppContext } from '../../../providers/app-provider';
import { PlansQuotaData } from './constants';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '10px 16px',
        borderBottom: 'none',
        textAlign: 'left',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none',
        padding: '10px 16px',
        textAlign: 'left',
    },
}));

interface IPricingCard {
    planName: string;
    recommended?: boolean;
    planRecommendation?: string;
    planType: string;
    description: string;
    priceDetails?: PriceDetails;
    features: string[];
    featuresTitle: string;
    cta: Cta;
    billingOptions?: BillingOptions;
    selectedBillingOption?: string;
}

export interface PriceDetails {
    price?: number;
    currency: string;
    billingPeriod: string;
    savingsLabel: string;
    originalPrice: any;
    totalCostPerYearLabel: string;
}

export interface Cta {
    buttonLabel: string;
    buttonColor: string;
}

export interface BillingOptions {
    monthly: Monthly;
    annual: Annual;
}

export interface Monthly {
    price: number;
    currency: string;
    savingsLabel: string;
    originalPrice: number;
    totalCostPerYearLabel: string;
}

export interface Annual {
    price: number;
    currency: string;
    savingsLabel: string;
    originalPrice: number;
    totalCostPerYearLabel: string;
}

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    padding: '2px 4px',
    backgroundColor: '#fff', // or your preferred background color
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&:not(:first-of-type)': {
            borderRadius: '8px',
        },
        '&:first-of-type': {
            borderRadius: '8px',
        },
    },
}));

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: '8px',
    textTransform: 'none',
    fontWeight: 500,
    padding: '4px 16px',
    color: '#003',
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: '#EBECF6', // Background color when selected
        border: '1px solid #9C9FD0 !important',
    },
    '&:hover': {
        backgroundColor: '#f0f4ff',
    },
}));

const pricingPlans: IPricingCard[] = [
    {
        planName: 'Free',
        planType: '',
        description:
            'Get a taste of Quantuminvest with a trial of our services.',
        priceDetails: {
            price: 0,
            currency: 'USD',
            billingPeriod: 'One-time',
            savingsLabel: '',
            originalPrice: null,
            totalCostPerYearLabel: '',
        },
        featuresTitle: 'Key Features (Limited)',
        features: [
            'AI Search Engine with Citations from Web Articles',
            'Chat with documents (one at a time)',
            '25 AI Credits',
            '20K words of data',
            '10MB per file',
            'Latest GPT 4o Mini model',
            'Mobile App',
            'Browser Extension',
        ],
        cta: {
            buttonLabel: 'Switch',
            buttonColor: 'default',
        },
    },
    {
        planName: 'Basic',
        planType: 'Popular Plan',
        description:
            "Don't buy everything if you only need one. Customize as per your need.",
        billingOptions: {
            monthly: {
                price: 19,
                currency: 'USD',
                savingsLabel: '',
                originalPrice: 49,
                totalCostPerYearLabel: 'Total Cost/Per month',
            },
            annual: {
                price: 190,
                currency: 'USD',
                savingsLabel: 'SAVE 2 MONTHS',
                originalPrice: 490,
                totalCostPerYearLabel: 'Total Cost/Per year',
            },
        },
        featuresTitle: 'Free Plus',
        features: [
            'Chat with entire web and custom docs (multiple docs at once, highly parameterizable)',
            '1500 AI Credits',
            '3M words of data',
            '50MB per file',
            '5 Customer facing Chatbots',
            'Lead Capturing',
            'Smart Search across all custom data',
            'Web Crawling',
            'API Access',
        ],
        cta: {
            buttonLabel: 'Pay Now & Upgrade',
            buttonColor: 'primary',
        },
    },
    {
        planName: 'Pro',
        recommended: true,
        planType: 'Recommended Plan',
        // planRecommendation: '(Chosen by 48% businesses)',
        description:
            "Don't buy everything if you only need one. Customize as per your need.",
        billingOptions: {
            monthly: {
                price: 49,
                currency: 'USD',
                savingsLabel: '',
                originalPrice: 99,
                totalCostPerYearLabel: 'Total Cost/Per month',
            },
            annual: {
                price: 990,
                currency: 'USD',
                savingsLabel: 'SAVE 2 MONTHS',
                originalPrice: 990,
                totalCostPerYearLabel: 'Total Cost/Per year',
            },
        },
        featuresTitle: 'Basic Plus',
        features: [
            'Insights Mode (Advanced analytics on topics)',
            'Sentiment Analysis',
            '5000 AI credits',
            '10M words of data',
            '100MB per file, including Audio/Video',
            '10 Customer facing Chatbots',
            '5 Diligence Reports',
            'Sync data from Dropbox, OneDrive, Web Crawling, etc.',
            'Access to GPT 4o',
        ],
        cta: {
            buttonLabel: 'Pay Now & Upgrade',
            buttonColor: 'primary',
        },
    },
    {
        planName: 'Enterprise',
        planType: '',
        description:
            'Have a question about something else? Contact our sales team.',
        priceDetails: {
            currency: 'USD',
            billingPeriod: 'Custom',
            savingsLabel: '',
            originalPrice: null,
            totalCostPerYearLabel: '',
        },
        featuresTitle: 'Features as you need',
        features: [
            'Comprehensive offering suite with higher quotas',
            'Whitelabeling of Diligence, Chat and more',
            'SOC2 and ISO Certificates provided',
            'Custom Data Integrations',
            'Custom Dedicated APIs',
            'Custom Report Templates',
            'Custom Branded Frontend',
            'Integrate with Slack/MS Teams',
            'Dedicated Support',
            'Guaranteed SLA',
        ],
        cta: {
            buttonLabel: 'Contact Us',
            buttonColor: 'primary',
        },
    },
];

interface PlanCardProps {
    plan: IPricingCard;
    subscriptions: ISubscription[];
    subscriptionInfo: IActiveSubscriptionResponseData | undefined;
}

const PLANS_SEQUENCE = ['FREE', 'BASIC', 'PRO', 'ENTERPRISE'];

const PlanCard: React.FC<PlanCardProps> = ({
    plan,
    subscriptions,
    subscriptionInfo,
}) => {
    const {
        planName,
        planType,
        recommended,
        description,
        planRecommendation,
        selectedBillingOption,
        priceDetails,
        featuresTitle,
        features,
        cta,
        billingOptions,
    } = plan;
    const [proratedAmount, setProratedAmount] = useState<
        IProratedAmountResponseData | undefined
    >(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [cancelSubscription, setCancelSubscription] =
        useState<boolean>(false);
    const appContext = useContext(AppContext);
    const _price = subscriptions.filter(
        (s) =>
            s.cycle ==
                (selectedBillingOption == 'annual' ? 'yearly' : 'monthly') &&
            s.plan_type == planName.toUpperCase()
    );
    const price = _price.length == 1 ? _price[0].price : '0';

    const originalPrice = billingOptions
        ? //@ts-ignore
          billingOptions[selectedBillingOption || 'annual'].originalPrice
        : priceDetails?.originalPrice;

    const savingsLabel = billingOptions
        ? //@ts-ignore
          billingOptions[selectedBillingOption || 'annual'].savingsLabel
        : priceDetails?.savingsLabel;

    const totalCostLabel = billingOptions
        ? //@ts-ignore
          billingOptions[selectedBillingOption || 'annual']
              .totalCostPerYearLabel
        : priceDetails?.totalCostPerYearLabel;

    const { buySubscription, fetchProratedAmount, fetchActiveSubscription } =
        useBillingService();

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openMissingPaymentMethodDialog, setOpenMissingPaymentMethodDialog] =
        useState(false);
    const { addQueryParams } = useQueryparamServices();

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
    };

    const handleMissingPaymentMethodDialogClose = () => {
        setOpenMissingPaymentMethodDialog(false);
    };

    const handleDialogAction = async () => {
        // Handle the upgrade logic here
        setIsProcessing(true);
        const buyResponse = await buySubscription(
            getButtontext() == 'Cancel'
                ? 'free'
                : `${planName.toLowerCase()}_${
                      selectedBillingOption == 'annual' ? 'yearly' : 'monthly'
                  }`
        );
        setIsProcessing(false);
        if (buyResponse.success) {
            enqueueSnackbar(`Plan updated successfully`, {
                variant: 'success',
            });
            setOpenConfirmDialog(false);
            setOpenMissingPaymentMethodDialog(false);
            window.location.reload();
        } else {
            if (buyResponse.data.code == 'NO_CARD_ADDED') {
                setOpenConfirmDialog(false);
                setOpenMissingPaymentMethodDialog(true);
            } else {
                enqueueSnackbar(`Plan update failed`, {
                    variant: 'error',
                });
            }
        }
    };

    const handlePayClick = async () => {
        if (planName == 'Enterprise') {
            window.open('https://photoninsights.com/contact', '_blank');
            return;
        }

        if (planName == 'Free') {
            handleDialogAction();
            return;
        }
        if (getButtontext() == 'Cancel') {
            setCancelSubscription(true);
            return;
        }
        if (getButtontext() == 'Renew') {
            handleDialogAction();
            return;
        }
        setIsProcessing(true);
        const proratedAmount = await fetchProratedAmount(
            `${planName.toLowerCase()}_${
                selectedBillingOption == 'annual' ? 'yearly' : 'monthly'
            }`
        );
        setIsProcessing(false);
        setProratedAmount(proratedAmount);
        setOpenConfirmDialog(true);
    };
    const handleAddBillingMethod = () => {
        addQueryParams('subTab', 'BILLING_METHOD');
    };

    const isOnTrial = () => {
        return subscriptionInfo?.status === 'trialing';
    };

    const getButtontext = () => {
        if (!subscriptionInfo) {
            return 'Subscribe';
        }
        const activeBillingCycle =
            subscriptionInfo.plan.split('_')[1] == 'yearly'
                ? 'annual'
                : 'monthly';
        if (planName.toUpperCase() === 'ENTERPRISE') {
            return 'Contact Us';
        }
        if (isOnTrial()) {
            if (planName.toUpperCase() === 'FREE') {
                return 'Downgrade';
            } else {
                return 'Pay Now & Upgrade';
            }
        } else if (subscriptionInfo.cancellation_date) {
            if (planName.toUpperCase() === 'FREE') {
                return 'Downgrade';
            } else if (
                PLANS_SEQUENCE.indexOf(planName.toUpperCase()) <
                PLANS_SEQUENCE.indexOf(subscriptionInfo.plan_type.toUpperCase())
            ) {
                return 'Downgrade';
            } else if (
                PLANS_SEQUENCE.indexOf(planName.toUpperCase()) ===
                    PLANS_SEQUENCE.indexOf(
                        subscriptionInfo.plan_type.toUpperCase()
                    ) &&
                activeBillingCycle !== selectedBillingOption
            ) {
                return 'Update';
            } else if (
                PLANS_SEQUENCE.indexOf(planName.toUpperCase()) ===
                PLANS_SEQUENCE.indexOf(subscriptionInfo.plan_type.toUpperCase())
            ) {
                return 'Renew';
            } else {
                return 'Pay Now & Upgrade';
            }
        } else if (!subscriptionInfo.cancellation_date) {
            if (planName.toUpperCase() === 'FREE') {
                return 'Downgrade';
            } else if (
                PLANS_SEQUENCE.indexOf(planName.toUpperCase()) <
                PLANS_SEQUENCE.indexOf(subscriptionInfo.plan_type.toUpperCase())
            ) {
                return 'Downgrade';
            } else if (
                PLANS_SEQUENCE.indexOf(planName.toUpperCase()) ===
                    PLANS_SEQUENCE.indexOf(
                        subscriptionInfo.plan_type.toUpperCase()
                    ) &&
                activeBillingCycle !== selectedBillingOption
            ) {
                return 'Update';
            } else if (
                PLANS_SEQUENCE.indexOf(planName.toUpperCase()) ===
                PLANS_SEQUENCE.indexOf(subscriptionInfo.plan_type.toUpperCase())
            ) {
                return 'Cancel';
            } else {
                return 'Pay Now & Upgrade';
            }
        }
        return '';
    };

    const getDialogButtonText = () => {
        let buttonText:
            | ''
            | 'Pay Now & Upgrade'
            | 'Contact Us'
            | 'Subscribe'
            | 'Downgrade'
            | 'Renew'
            | 'Update'
            | 'Cancel' = getButtontext();

        switch (buttonText) {
            case 'Pay Now & Upgrade':
                return 'Upgrade';
            default:
                return buttonText;
        }
    };

    return (
        <Card
            variant="outlined"
            sx={{
                maxWidth: 345,
                flexGrow: 1,
                borderColor: recommended ? 'primary.main' : 'grey.300',
                borderRadius: 2,
                backgroundColor: '#F9F9FF',

                margin: 'auto',
                position: 'relative',
                '&:hover': {
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                },
            }}
        >
            <Box height={34}>
                {planType && (
                    <Box
                        sx={{
                            backgroundColor: '#EBECF6',
                            mx: 12,
                            borderRadius: '0px 0px 10px 10px',
                            padding: '8px',
                            textAlign: 'center',
                            borderBottom: '1px solid',
                            borderLeft: '1px solid',
                            borderRight: '1px solid',
                            borderColor: recommended
                                ? 'primary.main'
                                : 'grey.300',
                        }}
                    >
                        <Typography variant="subtitle2" color="#003">
                            {planType}
                        </Typography>
                        {planRecommendation && (
                            <Box>
                                {' '}
                                <Typography variant="subtitle2" color="#003">
                                    {planRecommendation}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <CardContent sx={{ padding: '16px !important' }}>
                <Typography variant="h4" align="center">
                    {planName}
                </Typography>
                <Typography align="center" sx={{ my: 4 }}>
                    {description}
                </Typography>

                <Box
                    textAlign="center"
                    sx={{
                        backgroundColor: totalCostLabel
                            ? '#fff'
                            : 'transparent',
                        py: totalCostLabel ? 3 : 0,
                        border: totalCostLabel ? '1px solid #ccc' : '',
                        borderRadius: 2,
                    }}
                    mb={2}
                >
                    <Typography variant="body2" sx={{ color: 'textPrimary' }}>
                        {totalCostLabel}
                    </Typography>
                    {planName !== 'Enterprise' && (
                        <Box justifyContent={'center'} display={'flex'}>
                            {price !== undefined && originalPrice > price && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        textDecoration: 'line-through',
                                        color: '#4A4B4C',
                                        mt: 5,
                                        mr: 2,
                                    }}
                                >
                                    {originalPrice !== undefined
                                        ? `$${originalPrice}`
                                        : ''}
                                </Typography>
                            )}
                            {price !== undefined ? (
                                <Typography fontSize={36} fontWeight={700}>
                                    ${price}
                                </Typography>
                            ) : (
                                <SupportAgentIcon fontSize={'large'} />
                            )}
                        </Box>
                    )}
                    {planName == 'Enterprise' && (
                        <Box justifyContent={'center'} pb={2} display={'flex'}>
                            <SupportAgentIcon fontSize={'large'} />
                        </Box>
                    )}

                    {savingsLabel && (
                        <Chip
                            label={savingsLabel}
                            color="success"
                            size="small"
                            sx={{
                                fontSize: '0.75rem',
                                fontWeight: 'bold',
                                mt: 4,
                            }}
                        />
                    )}
                </Box>
                <Divider sx={{ my: 4 }} />
                <Box mb={2} display="flex">
                    <StarBorderOutlinedIcon sx={{ mt: 0.5 }} />
                    <Typography fontSize={18} ml={2} fontWeight={600}>
                        {featuresTitle}
                    </Typography>
                </Box>
                <Stack sx={{ pb: 18 }} spacing={0}>
                    {features.map((feature, index) => (
                        <Box
                            display="flex"
                            py={2}
                            alignItems="center"
                            key={index}
                        >
                            <CheckCircleIcon
                                fontSize="small"
                                color="success"
                                sx={{ mr: 2 }}
                            />
                            <Typography color="#4A4B4C" variant="body2">
                                {feature}
                            </Typography>
                        </Box>
                    ))}
                </Stack>
                {subscriptionInfo && planName !== 'Free' && (
                    <Button
                        variant={'contained'}
                        fullWidth
                        disabled={isProcessing}
                        onClick={handlePayClick}
                        sx={{
                            fontWeight: 'bold',
                            textTransform: 'none',
                            borderRadius: 2,
                            mt: 'auto',
                            padding: '8px 16px',
                            fontSize: '1rem',
                            lineHeight: '20px',
                            color: cta.buttonColor as 'primary' | 'default',
                            position: 'absolute',
                            bottom: 20,
                            width: `calc(100% - 40px)`,
                        }}
                    >
                        {isProcessing ? (
                            <CircularProgress
                                size={25}
                                sx={{
                                    color: '#ffffff',
                                    ml: 2,
                                }}
                            />
                        ) : (
                            getButtontext()
                        )}
                    </Button>
                )}
            </CardContent>
            {proratedAmount && (
                <BootstrapDialogSmall
                    open={openConfirmDialog}
                    onClose={handleConfirmDialogClose}
                >
                    <UpgradeOutlinedIcon fontSize="large" />
                    <DialogTitle sx={{ px: 2 }}>
                        {getDialogButtonText()}/Switch Subscription
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            By clicking {getDialogButtonText()}, you will
                            subscribe to the {planName} Plan for{' '}
                            <strong>
                                ${price}/
                                {selectedBillingOption == 'monthly'
                                    ? 'Month'
                                    : 'Year'}
                            </strong>
                            .
                            {proratedAmount.amount > 0 && (
                                <>
                                    Your Credit Card will be charged{' '}
                                    <strong>${proratedAmount.amount}</strong>{' '}
                                    {
                                        ' immediately for the remaining days in your billing cycle.'
                                    }
                                </>
                            )}
                            {proratedAmount.amount < 0 && (
                                <>
                                    You will be provided with $
                                    {Math.abs(proratedAmount.amount)} credits
                                    which will be adjusted in your next billing
                                    cycle
                                </>
                            )}
                        </Typography>
                        <BorderedBox
                            mt={2}
                            sx={{ backgroundColor: '#F9F9FF' }}
                            p={2}
                        >
                            <Typography variant="h6">
                                {planName} Plan
                            </Typography>
                            <Typography variant="body1">
                                ${price}/
                                {selectedBillingOption == 'monthly'
                                    ? 'Month'
                                    : 'Year'}
                            </Typography>
                            {savingsLabel && (
                                <Chip
                                    label={savingsLabel}
                                    color="success"
                                    size="small"
                                    sx={{
                                        fontSize: '0.75rem',
                                        fontWeight: 'bold',
                                        mt: 1,
                                    }}
                                />
                            )}
                        </BorderedBox>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleConfirmDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDialogAction}
                            color="primary"
                            disabled={isProcessing}
                            variant="contained"
                        >
                            {isProcessing ? (
                                <CircularProgress
                                    size={25}
                                    sx={{
                                        color: '#ffffff',
                                        ml: 2,
                                    }}
                                />
                            ) : (
                                getDialogButtonText()
                            )}
                        </Button>
                    </DialogActions>
                </BootstrapDialogSmall>
            )}
            {subscriptionInfo && (
                <BootstrapDialogSmall
                    open={cancelSubscription}
                    onClose={() => setCancelSubscription(false)}
                >
                    <DialogTitle sx={{ px: 2 }}>
                        Cancel Subscription
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Your plan will be cancelled, and you will moved to
                            free tier on{' '}
                            {UtilsService.formatStringDate(
                                subscriptionInfo.current_period_end
                            )}
                            . Are you sure you want to cancel the plan ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setCancelSubscription(false)}
                            color="primary"
                        >
                            Discard
                        </Button>
                        <Button
                            onClick={handleDialogAction}
                            color="primary"
                            disabled={isProcessing}
                            variant="contained"
                        >
                            Cancel Subscription
                        </Button>
                    </DialogActions>
                </BootstrapDialogSmall>
            )}
            <BootstrapDialogSmall
                open={openMissingPaymentMethodDialog}
                onClose={handleMissingPaymentMethodDialogClose}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" mb={2}>
                        <PaymentIcon fontSize="large" />
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        Billing method is missing!
                    </Typography>
                    <Typography>
                        Billing method is missing. Please add a billing method
                        to upgrade the plan.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAddBillingMethod}
                        color="primary"
                        variant="contained"
                        fullWidth
                    >
                        Add Billing Method
                    </Button>
                </DialogActions>
            </BootstrapDialogSmall>
        </Card>
    );
};
interface IConfigurationItemProps {
    data: string;
}
const ConfigurationItem = (props: IConfigurationItemProps) => {
    return (
        <>
            {props.data == 'yes' ? (
                <CheckIcon fontSize="small" color="success" sx={{ mr: 1 }} />
            ) : props.data == '-' ? (
                '-'
            ) : (
                props.data
            )}
        </>
    );
};

interface IPricingSectionProps {
    subscriptionInfo: IActiveSubscriptionResponseData | undefined;
}

export const PricingSection = (props: IPricingSectionProps) => {
    const [selectedBillingOption, setSelectedBillingOption] =
        useState('annual');

    const { fetchSubscriptions } = useBillingService();

    const handleBillingOptionChange = (
        event: React.MouseEvent<HTMLElement>,
        newBillingOption: string
    ) => {
        if (newBillingOption !== null) {
            setSelectedBillingOption(newBillingOption);
        }
    };
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
    const initialize = async () => {
        const res = await fetchSubscriptions();
        setSubscriptions(res);
    };
    useEffect(() => {
        initialize();
    }, []);

    return (
        <BorderedContainedBox>
            <Typography variant="h4" mb={4}>
                Upgrade Plan Options
            </Typography>
            <Box sx={{ width: '100%', mx: 'auto', maxWidth: 400, my: 6 }}>
                <CustomToggleButtonGroup
                    value={selectedBillingOption}
                    exclusive
                    fullWidth
                    onChange={handleBillingOptionChange}
                >
                    <CustomToggleButton value="monthly">
                        Monthly
                    </CustomToggleButton>
                    <CustomToggleButton value="annual">
                        Annual
                    </CustomToggleButton>
                </CustomToggleButtonGroup>
            </Box>
            <Grid
                container
                spacing={3}
                // sx={{ alignItems: 'center' }}
                justifyContent="center"
                alignItems="stretch"
            >
                {pricingPlans.map((plan, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        display="flex"
                        flexDirection="column"
                        key={index}
                    >
                        <PlanCard
                            plan={{ ...plan, selectedBillingOption }}
                            subscriptions={subscriptions}
                            subscriptionInfo={props.subscriptionInfo}
                        />
                    </Grid>
                ))}
            </Grid>

            <BorderedBox mt={10} sx={{ backgroundColor: '#FFFFFF' }} pt={3}>
                <Box>
                    <Typography px={4} variant="h6">
                        Configuration
                    </Typography>
                    <Box pt={2}>
                        <Divider />
                    </Box>
                    <Box pb={3}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 700 }}
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            Features
                                        </StyledTableCell>
                                        <StyledTableCell>Free</StyledTableCell>
                                        <StyledTableCell>Basic</StyledTableCell>
                                        <StyledTableCell>Pro</StyledTableCell>
                                        <StyledTableCell>
                                            Enterprise
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {PlansQuotaData.map((groupData: any) => (
                                        <>
                                            {/* Group Header */}
                                            <TableRow
                                                sx={{
                                                    borderTop:
                                                        '1px solid #e3e3e3',
                                                }}
                                            >
                                                <StyledTableCell
                                                    sx={{
                                                        p: '16px !important',
                                                    }}
                                                    colSpan={5}
                                                >
                                                    <Typography variant="h6">
                                                        {groupData.group}
                                                    </Typography>
                                                </StyledTableCell>
                                            </TableRow>

                                            {/* Group Items */}
                                            {groupData.items.map(
                                                (row: any, index: number) => (
                                                    <TableRow
                                                        key={row.feature}
                                                        sx={{
                                                            backgroundColor:
                                                                index % 2 === 0
                                                                    ? '#F9F9FF'
                                                                    : '#FFFFFF',
                                                        }}
                                                    >
                                                        <StyledTableCell>
                                                            {row.feature}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <ConfigurationItem
                                                                data={row.free}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <ConfigurationItem
                                                                data={row.basic}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <ConfigurationItem
                                                                data={row.pro}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <ConfigurationItem
                                                                data={
                                                                    row.enterprise
                                                                }
                                                            />
                                                        </StyledTableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </BorderedBox>
        </BorderedContainedBox>
    );
};

export default PricingSection;
